<template>
    <div>
        <el-collapse>
            <el-collapse-item title="베팅통계 보기" name="1">
                <h5>일반 스포츠</h5>
                <table class="table100" style="border-bottom: 1px solid #00ffff">
                    <tr>
                        <th>베팅폴더수</th>
                        <th>베팅건수</th>
                        <th>당첨건수</th>
                        <th>당첨확율</th>
                        <th>베팅금액</th>
                        <th>당첨금액</th>
                        <th>손익금</th>
                    </tr>
                    <tr v-for="item in sportsBetInfoStatistic">
                        <th>{{item.tattrName}}&nbsp;폴더</th>
                        <td>{{item.tcount}}&nbsp;건</td>
                        <td>{{item.winCount}}&nbsp;건</td>
                        <td>
                            <span :class="{'text-danger':item.winningProbability >= 50}">{{item.winningProbability}}%</span>
                        </td>
                        <td>{{item.tamount | comma}}&nbsp;원</td>
                        <td>{{item.twinAmount | comma}}&nbsp;원</td>
                        <td>
                             <span class="text-danger"
                                   :class="{'text-danger':item.tamount - item.twinAmount < 0}">
                                 {{(item.tamount - item.twinAmount) | comma}} &nbsp;원
                             </span>
                        </td>
                    </tr>
                </table>
                <h5>인플레이</h5>
                <table class="table100" style="border-bottom: 1px solid #00ffff">
                    <tr>
                        <th>베팅폴더수</th>
                        <th>베팅건수</th>
                        <th>당첨건수</th>
                        <th>당첨확율</th>
                        <th>베팅금액</th>
                        <th>당첨금액</th>
                        <th>손익금</th>
                    </tr>
                    <tr v-for="item in inplayBetInfoStatistic">
                        <th>{{item.tattrName}}&nbsp;폴더</th>
                        <td>{{item.tcount}}&nbsp;건</td>
                        <td>{{item.winCount}}&nbsp;건</td>
                        <td>
                            <span :class="{'text-danger':item.winningProbability >= 50}">{{item.winningProbability}}%</span>
                        </td>
                        <td>{{item.tamount | comma}}&nbsp;원</td>
                        <td>{{item.twinAmount | comma}}&nbsp;원</td>
                        <td>
                             <span class="text-danger"
                                   :class="{'text-danger':item.tamount - item.twinAmount < 0}">
                                 {{(item.tamount - item.twinAmount) | comma}} &nbsp;원
                             </span>
                        </td>
                    </tr>
                </table>
            </el-collapse-item>
        </el-collapse>
        <div class="search">
            <div class="date_selector">
                <div class="block">
                    <span class="demonstration"></span>
                    <el-date-picker size="mini"
                                    style="width: 130px"
                                    v-model="startDate"
                                    align="right"
                                    type="date"
                                    placeholder="시작일"
                                    :picker-options="pickerOptions"
                                    @change="setStartDate">
                    </el-date-picker>
                </div>
                <div style="padding-left: 1px;padding-right: 1px;color: #cfcfd1">~</div>
                <div class="block">
                    <span class="demonstration"></span>
                    <el-date-picker size="mini"
                                    style="width: 130px"
                                    v-model="endDate"
                                    align="right"
                                    type="date"
                                    placeholder="마감일"
                                    :picker-options="pickerOptions"
                                    @change="setEndDate">
                    </el-date-picker>
                </div>
            </div>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색
            </el-button>

            <el-select v-model="sportsBet.isinplay" @change="getSportsBetList" size="mini" placeholder="스포츠 구분"
                       style="width:110px;margin-left: 15px">
                <el-option key="5" label="전체" :value="null">전체</el-option>
                <el-option key="6" label="스포츠" :value="managerConst.NOT">Sports</el-option>
                <el-option key="7" label="Inplay" :value="managerConst.YES">Inplay</el-option>
            </el-select>

            <el-select v-model="sportsBet.type" @change="getSportsBetList" size="mini" placeholder="베팅종류"
                       style="width:110px;margin-left: 5px">
                <el-option key="8" label="전체" :value="null">전체</el-option>
                <el-option key="9" label="크로스" :value="managerConst.SportsBet.BET_TYPE_CROSS">크로스</el-option>
                <el-option key="10" label="1x2" :value="managerConst.SportsBet.BET_TYPE_1X2">1x2</el-option>
                <el-option key="11" label="핸디/오언" :value="managerConst.SportsBet.BET_TYPE_HO">핸디/오언</el-option>
                <el-option key="12" label="스페셜" :value="managerConst.SportsBet.BET_TYPE_SPECIAL">스페셜</el-option>
            </el-select>

            <el-select v-model="sportsBet.betResult" @change="getSportsBetList" size="mini" placeholder="결과선택"
                       style="width:100px;margin-left: 5px">
                <el-option key="13" label="전체" :value="null">전체</el-option>
                <el-option key="14" label="대기" :value="managerConst.SportsBet.BET_RESULT_WAITING">대기</el-option>
                <el-option key="15" label="당첨" :value="managerConst.SportsBet.BET_RESULT_WIN">당첨</el-option>
                <el-option key="16" label="낙첨" :value="managerConst.SportsBet.BET_RESULT_LOSE">낙첨</el-option>
                <el-option key="17" label="취소" :value="managerConst.SportsBet.BET_RESULT_CANCEL">취소</el-option>
                <el-option key="18" label="적특" :value="managerConst.SportsBet.BET_RESULT_SPECIALCASE">적특
                </el-option>
            </el-select>
        </div>
        <div style="padding: 5px 0;font-size: 14px">
            <span :class="{'text-danger':totalWinnerCash < 0,'text-blue':totalWinnerCash>0}">수익금:{{totalWinnerCash|comma}} 원</span>
            <span style="padding-left: 20px;">총건수:{{totalBetCount|comma}}건</span>
            <span style="padding-left: 20px;">
                        총베팅금액:{{totalBetCash|comma}}원
                    </span>
            <span style="padding-left: 20px;">
                        베팅진행중금액: {{totalBetingCash|comma}} 원
                    </span>
            <span style="padding-left: 20px;">당첨금액:{{totalBetWinCash|comma}} 원</span>
            <span style="padding-left: 20px;">낙첨금액:{{totalBetLoseCash|comma}} 원</span>
            <span style="padding-left: 20px;">적특:{{totalSpecial|comma}} 원</span>
            <span style="padding-left: 20px;">취소:{{totalCancel|comma}} 원</span>
        </div>
        <sports-bet-info-comp :key="2000" :list="sportsBetList" @refresh="refresh"></sports-bet-info-comp>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[10,50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>

</template>

<script>
    import managerConst from "../../common/administrator/managerConst";
    import {getSportsBetInfoList, getStatisticByAttribute} from "../../network/manager/sportsBetRequest";

    import DateSelectorComp from "./DateSelectorComp";
    export default {
        name: "UserInfoSportsBetComp",
        components: {SportsBetInfoComp: () => import('./SportsBetInfoComp.vue'), DateSelectorComp},
        data() {
            return {
                startDate: '',
                endDate: '',
                sportsBet: {partnerId: null, user: {}},
                sportsBetList: [],
                sportsBetInfoStatistic: {},
                inplayBetInfoStatistic: {},
                sportsBetInfoStatisticLatelySearch: {},
                pageNum: 1,
                pageSize: 10,
                pageTotal: 0,
                orderBy: 'id DESC',
                orderByText: '시간순',

                BET_KIND_LIST: [
                    {"kindName": "크로스", "idx": managerConst.BET_TYPE_CROSS},
                    {"kindName": "1x2", "idx": managerConst.BET_TYPE_1X2},
                    {"kindName": "핸/오언", "idx": managerConst.BET_TYPE_HO},
                    {"kindName": "스페셜", "idx": managerConst.BET_TYPE_SPECIAL},
                    {"kindName": "라이브", "idx": managerConst.BET_TYPE_LIVE}
                ],
                totalBetCount: 0,
                totalBetCash: 0,
                totalBetingCash: 0,
                totalBetWinCash: 0,
                totalBetLoseCash: 0,
                totalWinnerCash: 0,
                totalSpecial: 0,
                totalCancel: 0,
                managerConst: managerConst,
                pickerOptions: {
                    shortcuts: [{
                        text: '오늘',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '어제',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '일주일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '15일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '30일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '60일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 60);
                            picker.$emit('pick', date);
                        }
                    }]
                },
            }
        },
        props: {
            uid: {
                type: Number,
                default() {
                    return 0;
                }
            },

        },
        methods: {
            search(){
                this.getSportsBetList()
                this.getSportsStatistic();
                this.getInplayStatistic();
            },
            getSportsBetList() {
                this.sportsBet.startDate = this.startDate
                this.sportsBet.endDate = this.endDate
                getSportsBetInfoList(this.sportsBet, this.pageNum, this.pageSize, this.orderBy).then(res => {
                    this.pageTotal = res.data.total;
                    this.sportsBetList = res.data.data.betList;
                    this.totalBetCount = res.data.total;
                    this.totalBetCash = res.data.data.cashInfo.totalBetCash;
                    this.totalBetingCash = res.data.data.cashInfo.totalBetingCash;
                    this.totalBetWinCash = res.data.data.cashInfo.totalBetWinCash;
                    this.totalBetLoseCash = res.data.data.cashInfo.totalBetLoseCash;
                    this.totalWinnerCash = res.data.data.cashInfo.totalWinnerCash;
                    this.totalSpecial = res.data.data.cashInfo.totalSpecial;
                    this.totalCancel = res.data.data.cashInfo.totalCancel;
                })

            },
            getSportsStatistic() {
                this.sportsBetInfoStatisticLatelySearch.startDate = this.startDate;
                this.sportsBetInfoStatisticLatelySearch.endDate = this.endDate;
                this.sportsBetInfoStatisticLatelySearch.isinplay = managerConst.NOT;
                getStatisticByAttribute(this.sportsBetInfoStatisticLatelySearch).then(res => {
                    this.sportsBetInfoStatistic = res.data.data;
                });
            },
            getInplayStatistic() {
                this.sportsBetInfoStatisticLatelySearch.startDate = this.startDate;
                this.sportsBetInfoStatisticLatelySearch.endDate = this.endDate;
                this.sportsBetInfoStatisticLatelySearch.isinplay = managerConst.YES;
                getStatisticByAttribute(this.sportsBetInfoStatisticLatelySearch).then(res => {
                    this.inplayBetInfoStatistic = res.data.data;
                });
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getSportsBetList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getSportsBetList()
            },
            setStartDate(date) {
                this.startDate = this.$moment(date).format('yyyy-MM-DD');
            },
            setEndDate(date) {
                this.endDate = this.$moment(date).format('yyyy-MM-DD')
            },
            setPartnerId(parterId) {
                this.sportsBet.partnerId = parterId
                this.search()
            },
            refresh(){
                this.search()
            }
        },
        created() {

        },
        watch: {
            uid: {
                handler(newVal) {
                    if (newVal != null && newVal != 0) {
                        this.startDate = this.$moment().subtract(1, 'days').format('yyyy-MM-DD')
                        this.endDate = this.$moment().format('yyyy-MM-DD')
                        this.sportsBet.userId = this.uid
                        this.sportsBetInfoStatisticLatelySearch.userId = this.uid
                        this.search()
                    }

                },
                immediate: true,
                deep: false
            },
        }
    }
</script>

<style scoped>

</style>